import React from 'react';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { styles } from './RestaurantItem.style';

type PropTypes = {
  name: string;
  image: string;
  externalId: string;
};

// todo: return a nicely formatted text with the name of the place when there's no image

export const RestaurantItem = ({ name, image, externalId }: PropTypes): React.ReactElement => {
  const restaurantUrl = `https://menu.bluelobster.app/en/core/menu/${externalId}`;
  const { width } = useWindowDimensions();

  return (
    <a href={restaurantUrl} target='_blank' rel='noreferrer noopener'>
      <img style={width > 1000 ? styles.imageLarge : styles.image} src={image} alt={name} />
    </a>
  );
};
