import React from 'react';

import { styles } from './header.styles';
import logo from '../../assets/logo.png';

import { useWindowDimensions } from '../../hooks/useWindowDimensions';

export const Header = () => {
  const { width } = useWindowDimensions();

  return (
    <div style={styles.container}>
      <img src={logo} alt='blue lobster logo' style={width < 1000 ? styles.image : styles.imageLarge} />
    </div>
  );
};
