import { CSSProperties } from 'react';

export interface StylesDictionary {
  [Key: string]: CSSProperties;
}

export const styles: StylesDictionary = {
  item: {
    fontFamily: 'Chivo, sans-serif',
    alignSelf: 'center',
    textAlign: 'center',
    fontWeight: 300,
    margin: 0,
    width: 100,
    height: 100,
    padding: 10,
    display: 'table-cell',
    verticalAlign: 'middle',
  },

  imageLarge: {
    fontFamily: 'Chivo, sans-serif',
    fontWeight: 300,
    display: 'table-cell',
    verticalAlign: 'middle',
    alignSelf: 'center',
    textAlign: 'center',
    padding: 10,
    width: 200,
    height: 200,
  },
  link: {
    textDecoration: 'none' as 'none',
    color: 'black',
  },
};
