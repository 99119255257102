import { CSSProperties } from 'react';

export interface StylesDictionary {
  [Key: string]: CSSProperties;
}

export const styles: StylesDictionary = {
  container: {
    display: 'inline-block',
  },

  image: {
    padding: 5,
    verticalAlign: 'middle',
    width: 100,
    height: 100,
    alignSelf: 'center',
    objectFit: 'contain',
  },

  imageLarge: {
    padding: 5,
    width: 200,
    height: 200,
    alignSelf: 'center',
    objectFit: 'contain' as 'contain',
  },
};
