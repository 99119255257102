export const styles = {
  appContainer: {
    fontFamily: 'Chivo, sans-serif',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column' as 'column',
  },

  h1: {
    fontWeight: 800,
    textAlign: 'center' as 'center',
    fontSize: 30,
    marginBottom: 10,
  },

  h2: {
    fontWeight: 400,
    textAlign: 'center' as 'center',
    fontSize: 24,
    marginTop: 0,
  },

  detailsText: {
    fontWeight: 300,
    textAlign: 'center' as 'center',
    fontSize: 16,
    marginTop: 10,
  },

  textSectionMobile: {
    marginTop: 20,
    marginRight: 10,
    marginLeft: 10,
  },

  textSectionDesktop: {
    marginTop: 20,
    marginRight: 250,
    marginLeft: 250,
  },
};
