export const styles = {
  h1: {
    fontWeight: 800,
    textAlign: 'center' as 'center',
    fontSize: 30,
    marginBottom: 5,
    textTransform: 'uppercase' as 'uppercase',
  },

  p: {
    fontWeight: 300,
    textAlign: 'center' as 'center',
    fontSize: 16,
    marginTop: 10,
  },
  faqButton: {
    display: 'flex',
    justifyContent: 'center',
    textDecoration: 'none',
  },

  downloadAppContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  downloadApp: {
    display: 'block',
    width: 200,
    marginBottom: 20,
  },
  downloadAppApple: {
    display: 'block',
    height: 52,
    marginBottom: 20,
  },
};
