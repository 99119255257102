export const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    width: '80%',
    marginHorizontal: 0,
    paddingHorizontal: 0,
  },

  imageLarge: {
    width: '50%',
    marginHorizontal: 0,
    paddingHorizontal: 0,
  },
};
