import React from 'react';

import { styles } from './styles';

type propType = {
  text: string;
};

export const PureTextBlock = ({ text }: propType): React.ReactElement => {
  return <div style={styles.textContainer}>{text}</div>;
};
