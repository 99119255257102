import React from 'react';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { styles } from './NoPictureRestaurant.style';

type PropTypes = {
  name: string;
  externalId: string;
};

export const NoPictureRestaurant = ({ name, externalId }: PropTypes) => {
  const { width } = useWindowDimensions();

  const restaurantUrl = `https://menu.bluelobster.app/da/core/menu/${externalId}`;
  return (
    <a style={styles.link} href={restaurantUrl} target='_blank' rel='noreferrer noopener'>
      <span style={width > 1000 ? styles.imageLarge : styles.item}>{name}</span>
    </a>
  );
};
