export type languageStringsType = {
  header: string;
  caughtBy: string;
  in: string;
  landed: string;
  uses: string;
  fishingMethod: string;
  boatName: string;
  boatLength: string;
  meters: string;
  long: string;
  harborFacts: string;
  fishingFacts: string;
  didYouKnow: string;
  noFish: string;
  footer: FooterStrings;
};

type FooterStrings = {
  questions: string;
  findAnswers: string;
  ourFAQ: string;
  visitFAQ: string;
  downloadApp: string;
};

export const en: languageStringsType = {
  header: 'On the menu now:',
  caughtBy: 'Caught by',
  in: 'in',
  landed: 'and landed in',
  uses: 'uses',
  fishingMethod: 'as the fishing method',
  boatName: 'His boat is named',
  boatLength: 'and it is',
  meters: 'meters',
  long: 'long',
  harborFacts: '28% of Danish fishing harbours have closed (since 2005)',
  fishingFacts: `This seafood is helping keep the smaller Danish fishing harbours alive, by buying straight from the fishermen and paying them a liveable wage. 
  If you think this is important, feel free to say "Thank you" to the kitchen staff - because it's more difficult to work with the small fishermen: the boats are dependent on weather, the fish are not the same size, and you can never be sure they catch what you need. \
  The chefs here are making a daily effort to support the environment and small producers.`,
  didYouKnow: 'Did you know?',
  noFish: 'The restaurant does not currently have Blue Lobster fish on the menu',
  footer: {
    questions: 'Questions?',
    findAnswers: 'Find answers to our most common questions on ',
    ourFAQ: 'our FAQ page.',
    visitFAQ: 'Visit our FAQ',
    downloadApp: 'Download the app now:',
  },
};

export const da: languageStringsType = {
  header: 'PÅ MENUEN NU:',
  caughtBy: 'Fanget af ',
  in: 'i',
  landed: 'og landet i',
  uses: 'bruger',
  fishingMethod: 'som fiskemetode',
  boatName: 'Hans både hedder',
  boatLength: 'og den er',
  meters: 'meter',
  long: ' lang',
  harborFacts: '28% af de danske fiskehavne har lukket ned (siden 2005)',
  fishingFacts: `Dette mad fra havet hjælper de små danske fiskehavne med at holde sig i live, ved at købe direkte fra fiskeren og betale dem en pris der giver en levedygtig løn. 
  Hvis du også mener at det her er vigtigt, vær hjertelig velkommen til at sige "Tusind tak" til køkkenpersonalet - fordi det er mere udfordrende at samarbejde med de små fiskere: fangsten er meget afhængig af vejret, fiskene er ikke ens i størrelsen, og så kan man aldrig være sikker på at de fanger præcis den fisk man har brug for. 
  Kokkene her laver en stor daglig indsats for at kunne støtte miljøet og de små producenter.`,
  didYouKnow: 'Vidste du?',
  noFish: 'Der ikke Blue Lobster fisk på menuen lige nu',
  footer: {
    questions: 'Spørgsmål?',
    findAnswers: 'Find svar på de mest almindelige spørgsmål, vi får ',
    ourFAQ: 'på vores FAQ-side',
    visitFAQ: 'Visit FAQ',
    downloadApp: `Download app'en nu!`,
  },
};
