import React from 'react';

import { styles } from './App.styles';
import { Header } from '../../components/header/Header';
import { Footer } from '../../components/footer/Footer';
import { RestaurantList } from '../../components/RestaurantList/RestaurantList';
import { PureTextBlock } from '../../components/pureTextBlock/PureTextBlock';

import { useWindowDimensions } from '../../hooks/useWindowDimensions';

export const App = (): React.ReactElement => {
  const descriptionText = `Here you can find all the restaurants in Copenhagen that are already using Blue Lobster for their fresh, sustainably caught seafood supply. You can find what they are serving on our fish tracker (just click on the restaurant logo).
  `;

  const { width } = useWindowDimensions();

  return (
    <div style={styles.appContainer}>
      <Header />
      <div style={width < 1000 ? styles.textSectionMobile : styles.textSectionDesktop}>
        <PureTextBlock text={descriptionText} />
      </div>
      <h1>Restaurants</h1>
      <RestaurantList />
      <Footer language={'en'} />
    </div>
  );
};
