import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { RestaurantItem } from '../RestaurantItem/RestaurantItem';
import { NoPictureRestaurant } from '../noPictureRestaurant/NoPictureRestaurant';

import { styles } from './Restaurant.styles';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';

type Companies = {
  name: string;
  external_id: string;
  image: string;
};

type ApiResponse = {
  companies: Companies[];
};

export const RestaurantList = (): React.ReactElement => {
  const [restaurants, setRestaurants] = useState<ApiResponse | null>();

  const { width } = useWindowDimensions();

  const fetchRestaurants = async (): Promise<ApiResponse | undefined> => {
    const baseUrl = 'https://backend.bluelobster.app/en/core/restaurants/copenhagen/';
    try {
      return (await axios.get(baseUrl)).data;
    } catch {
      console.error('Fetch failed');
    }
  };

  const generateRestaurantList = () => {
    return restaurants?.companies?.map((company) => {
      if (company.image === '') {
        return <NoPictureRestaurant name={company.name} externalId={company.external_id} key={company.external_id} />;
      } else {
        return (
          <RestaurantItem
            name={company.name}
            image={company.image}
            externalId={company.external_id}
            key={company.external_id}
          />
        );
      }
    });
  };

  useEffect(() => {
    fetchRestaurants().then((response) => {
      setRestaurants(response);
    });
  }, []);

  return (
    <div style={width < 1000 ? styles.container : { ...styles.container, marginLeft: 200, marginRight: 250 }}>
      {generateRestaurantList()}
    </div>
  );
};
